<template>
	<div class="myColor">
		<el-radio-group v-model='themecolor'>
			
			<el-radio label="0193D8">默认-0193D8</el-radio>
			<el-radio label="a25fbc">默认-a25fbc</el-radio>
			<el-radio label="02abfd">默认-02abfd</el-radio>
			<el-radio label="00FF00">默认-00FF00</el-radio>
			<el-radio label="00BCD4">默认-00BCD4</el-radio>
		</el-radio-group>
		<el-button type="primary"  >搜索</el-button>
		<el-button type="primary"  >搜索</el-button>
	</div>
</template>
<script>
	import {
		toggleClass
	} from '../../utils/index.js';
	export default {
		name: 'HelloWorld',
		data() {
			return {
				classH2: 'custome-0193D8'
			};
		},
		mounted() {
			toggleClass(document.body, 'custom- ' + this.themecolor);
			let curcolor =this.$store.state.themecolor;
			this.classH2 = 'custome-' + curcolor;
		},
		computed: {
			themecolor: {
				get() {
					return this.$store.state.themecolor;
				},
				set(val) {
					this.$store.commit('setThemeColor', val);
				}
			},
		},
		watch: {
			themecolor: {
				handler() {
					toggleClass(document.body, 'custom-' + this.themecolor)
				}
			},
		}
	}
</script>
